<template>
  <div class="container pt-3">
    <div class="card " style="max-width: 1000px;">
        <div class="row no-gutters">
            <div class="col-md-4">
                <img src="../assets/jan_klein.png" class="card-img rounded" alt="...">
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title">Dr. Ing. Jan Eilers</h5>
                    <p class="card-text">
                        Über 14 Jahren bin ich Systemingenieur am Deutschen Zentrum für Luft- und Raumfahrt gewesen. Während dieser Zeit habe ich viele Projekte im Bereich der Raumfahrttechnik begleitet.
                        Es entstand die Idee mit dem erworbenen Wissen und Fähigkeiten, klein und mittelständische Unternehmen im Rahmen der Digitalisierung zu unterstützen.
                    </p>
                    <a href="https://www.researchgate.net/profile/Jan_Eilers" target="_"><i class="fab fa-researchgate"></i>Researchgate</a>
                    |
                    <a href="https://www.xing.com/profile/Jan_Eilers8/cv" target="_"><i class="fa fa-xing"></i> XING</a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
